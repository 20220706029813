import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { IoLogoWhatsapp } from 'react-icons/io';
import { TiSocialFacebook, TiSocialInstagram, TiSocialYoutube } from 'react-icons/ti';
import {
  HeaderWrap,
  HeaderTop,
  HeaderBottom,
  FixedHeader,
  HeaderMain,
  HeaderLeft,
  HeaderMiddle,
  HeaderRight,
  HeaderTopDescription,
  HeaderNavigation,
  HeaderElement,
  FixedHeaderHeight,
  HeaderCol,
} from './styles';
import { MainMenu, MobileMenu } from '../../../components/menu';
import Text from '../../../components/ui/Text';
import BurgerButton from '../../../components/ui/burger-button';
import OffCanvas, { OffCanvasHeader, OffCanvasBody } from '../../../components/ui/off-canvas';
import Social from '../../../components/ui/Social';
import { SocialLink } from '../../../components/ui/Social/SocialLink/SocialLink';
import Logo from '../../../components/Logo';
import Button from '../../../components/ui/button';

const Header = ({ headerBottom: { burgerBtnElStyle }, menuBasedOnCookies }) => {
  const headerData = useStaticQuery(graphql`
    query headerQuery {
      allMenuJson {
        edges {
          node {
            id
            text
            link
            ignoreRoot
          }
        }
      }
      site {
        siteMetadata {
          social {
            facebook
            whatsapp
            instagram
            youtube
          }
        }
      }
    }
  `);

  const { allMenuJson } = headerData;
  const { facebook, whatsapp, instagram, youtube } = headerData.site.siteMetadata.social;
  const [offCanvasOpen, setOffcanvasOpen] = useState(false);
  const [fixedHeaderHeight, setFixedHeaderHeight] = useState(0);
  const [totalHeaderHeight, setTotalHeaderHeight] = useState(0);
  const [sticky, setSticky] = useState(false);
  const headerRef = useRef(null);
  const fixedRef = useRef(null);
  // const { isInPerson } = useSiteLocation({ searchOnCookies: menuBasedOnCookies });

  const mapMenuItem = item => ({
    ...item,
    text: item.text.toUpperCase(),
    link: item.link ? `${item.link}` : undefined,
    submenu: item.isSubmenu ? (item.submenu || []).map(mapMenuItem) : undefined,
    megamenu: item.megamenu
      ? item.megamenu.map(megamenuItem => ({
          ...megamenuItem,
          submenu: (megamenuItem.submenu || []).map(mapMenuItem),
        }))
      : null,
  });

  const menuItems = allMenuJson.edges.map(({ node }) => node).map(mapMenuItem);

  const offCanvasHandler = () => {
    setOffcanvasOpen(prevState => !prevState);
  };

  useEffect(() => {
    setFixedHeaderHeight(fixedRef.current.clientHeight);
    setTotalHeaderHeight(headerRef.current.clientHeight);
  }, []);

  useEffect(() => {
    const scrollHandler = () => {
      const scrollPos = window.scrollY;
      if (scrollPos > totalHeaderHeight) {
        setSticky(true);
      }

      if (scrollPos < fixedHeaderHeight) {
        setSticky(false);
      }
    };

    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, [sticky, fixedHeaderHeight, totalHeaderHeight]);

  return (
    <>
      <HeaderWrap ref={headerRef}>
        <HeaderTop borderBottom>
          <Container fluid>
            <Row>
              <Col lg={12}>
                <HeaderMain top>
                  <HeaderCol left>
                    <Text color="white">
                      <HeaderTopDescription>Especialista em Concursos Militares</HeaderTopDescription>
                    </Text>
                  </HeaderCol>
                  <HeaderCol right>
                    <HeaderElement>
                      <Social tooltip tooltip_position="bottom-left" tooltip_bg="dark" space="10px">
                        {whatsapp && (
                          <SocialLink path={whatsapp} title="Whatsapp">
                            <IoLogoWhatsapp />
                          </SocialLink>
                        )}
                        {facebook && (
                          <SocialLink path={facebook} title="Facebook">
                            <TiSocialFacebook />
                          </SocialLink>
                        )}
                        {instagram && (
                          <SocialLink path={instagram} title="Instagram">
                            <TiSocialInstagram />
                          </SocialLink>
                        )}
                        {youtube && (
                          <SocialLink path={youtube} title="Youtube">
                            <TiSocialYoutube />
                          </SocialLink>
                        )}
                      </Social>
                    </HeaderElement>
                    <Button ml="28px" size="xsmall" to="https://ead.maismilitar.com.br" target="_blank">
                      Já sou Aluno
                    </Button>
                  </HeaderCol>
                </HeaderMain>
              </Col>
            </Row>
          </Container>
        </HeaderTop>
        <HeaderBottom>
          <FixedHeader ref={fixedRef} isSticky={sticky}>
            <Container fluid>
              <Row>
                <Col lg={12}>
                  <HeaderMain>
                    <HeaderLeft>
                      <Link to="/">
                        <Logo />
                      </Link>
                    </HeaderLeft>
                    <HeaderMiddle>
                      <HeaderNavigation>
                        <MainMenu menuData={menuItems} />
                      </HeaderNavigation>
                    </HeaderMiddle>
                    <HeaderRight>
                      <HeaderElement {...burgerBtnElStyle} visibility={{ default: 'false', lg: 'true' }}>
                        <BurgerButton onClick={offCanvasHandler} />
                      </HeaderElement>
                    </HeaderRight>
                  </HeaderMain>
                </Col>
              </Row>
            </Container>
          </FixedHeader>
          <FixedHeaderHeight height={fixedHeaderHeight} />
        </HeaderBottom>
      </HeaderWrap>
      <OffCanvas scrollable isOpen={offCanvasOpen} onClick={offCanvasHandler}>
        <OffCanvasHeader onClick={offCanvasHandler}>
          <Logo />
        </OffCanvasHeader>
        <OffCanvasBody>
          <MobileMenu menuData={menuItems} />
        </OffCanvasBody>
      </OffCanvas>
    </>
  );
};

Header.propTypes = {
  headerBottom: PropTypes.object,
  menuBasedOnCookies: PropTypes.bool,
};

Header.defaultProps = {
  menuBasedOnCookies: false,
  headerBottom: {
    burgerBtnElStyle: {
      pl: '20px',
    },
  },
};

export default Header;
